'use strict';

class newPasswordController {
  //start-non-standard
  errors = {};
  submitted = false;
  //end-non-standard

  constructor(Auth, Modal) {
    this.Auth = Auth;
    this.Modal = Modal;
  }

  changePassword(form,Modal) {
    this.submitted = true;

    if (form.$valid) {
      this.Auth.changePassword(this.user.oldPassword, this.user.newPassword)
        .then(
          this.Modal.confirm.info({
            title: "Cambio de contraseña",
            msg: "La contraseña ha sido modificada correctamente"
          },function(err) {
            console.log(err)
         }))
        .catch(() => {
          form.oldPassword.$setValidity('mongoose', false);
          this.errors.error = 'Contraseña incorrecta';
          this.message = '';
        });
    }
  }
}

angular.module('mvpcarrefourApp')
  .controller('newPasswordController', newPasswordController);
